export const march = {
  type: 'card',
  month: 'march',
  monthEs: 'marzo',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/march_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/march_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
