// Show popups on certain pages
// Stop showing popup after 1 time load... may need to change how state works

//import mrgImage from 'assets/media/img/popups/florida-resident-discount-popup_B.png';
//import mrgImage from 'assets/media/img/popups/M-Sphere_Popup.jpg';
//import mrgImage from 'assets/media/img/popups/bingo_promo_en.jpg';
import mrgImage from 'assets/media/img/popups/July4th_bingo_websitepopup.jpeg';

import aidImage from 'assets/media/img/popups/bingo_promo_en.jpg';
import promoImage1 from 'assets/media/img/popups/bingo_promo_en.jpg';
import hubPopup from 'assets/media/img/popups/bingo_promo_en.jpg';
import jobFair from 'assets/media/img/popups/bingo_promo_en.jpg';
import HeroSection from 'components/CustomSections/HeroSection';

const d = new Date();

const scheduler = (time) => {
  if (time.start.year === '') {
    return false;
  }
  const startTime = new Date(
    `${time.start.month} ${time.start.day}, ${time.start.year}, ${time.start.time}`
  );
  const endTime = new Date(
    `${time.end.month} ${time.end.day}, ${time.end.year}, ${time.end.time}`
  );
  if (d > startTime && d < endTime) {
    return true;
  } else {
    return false;
  }
};

// Popup Content, change content here.
// linkType: "internal" or "external" or "".
// link: can be "" if needed.
/*
example use of scheduler
showPopup: scheduler({
    start: {
      year: "21",
      month: "May",
      day: "28",
      time: "14:25",
    },
    end: {
      year: "21",
      month: "May",
      day: "28",
      time: "14:34",
    },
  }),
*/
const miccosukeePopup = {
  name: 'miccosukeePopup',
  id: '01',
  showPopup: scheduler({
    start: {
      year: '21',
      month: 'November',
      day: '1',
      time: '',
    },
    end: {
      year: '21',
      month: 'December',
      day: '4',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: jobFair,
    alt: 'job fair',
    linkType: 'external',
    link: 'https://www.miccosukee.com/adp-job-board',
  },
};
const mrgPopup = {
  name: 'mrgPopup',
  id: '02',
  showPopup: scheduler({
    start: {
      year: '23',
      month: 'June',
      day: '1',
      time: '',
    },
    end: {
      year: '23',
      month: 'July',
      day: '5',
      time: '',
    },
  }),
  viewCount: 1,
  /*
  //USE THIS FOR SIGN UP ON CLICK OF POPUP 
  content: {
    image: mrgImage,
    alt: 'big game promotion',
    linkType: 'internal',
    link: '/mcr/signup',
  },
  */
  /*
  USE THIS FOR CLICK TO CALL ACCOMMODATIONS 
  content: {
    image: mrgImage,
    alt: 'resort promotion',
    linkType: 'external',
    link: 'tel:+13059252555',
  },
  */
  content: {
    image: mrgImage,
    alt: 'Bingo Promo',
    link:
      'https://mapa-media.s3.amazonaws.com/mcr/July4th_Bingo+Program_Web.pdf',
    linkType: 'external',
  },
};
const mrgGamingPromotionsPopup = {
  name: 'mrgGamingPromotions',
  id: '02',
  showPopup: scheduler({
    start: {
      year: '21',
      month: 'November',
      day: '1',
      time: '',
    },
    end: {
      year: '21',
      month: 'November',
      day: '30',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: promoImage1,
    linkType: '',
    link: '',
  },
};
const golfPopup = {
  name: 'golfPopup',
  id: '03',
  showPopup: scheduler({
    start: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
    end: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: aidImage,
    linkType: 'internal',
    link: '/test2',
  },
};
const villagePopup = {
  name: 'villagePopup',
  id: '04',
  showPopup: scheduler({
    start: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
    end: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: aidImage,
    linkType: 'internal',
    link: '/test1',
  },
};
const airboatsPopup = {
  name: 'airboatsPopup',
  id: '05',
  showPopup: scheduler({
    start: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
    end: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: aidImage,
    linkType: 'internal',
    link: '/test1',
  },
};
const miccosukeeEventsPopup = {
  name: 'miccosukeeEventsPopup',
  id: '06',
  showPopup: scheduler({
    start: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
    end: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: aidImage,
    linkType: 'internal',
    link: '/test1',
  },
};
const historyPopup = {
  name: 'historyPopup',
  id: '07',
  showPopup: scheduler({
    start: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
    end: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: aidImage,
    linkType: 'internal',
    link: '/test1',
  },
};
const administrationPopup = {
  name: 'administrationPopup',
  id: '08',
  showPopup: scheduler({
    start: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
    end: {
      year: '',
      month: '',
      day: '',
      time: '',
    },
  }),
  viewCount: 1,
  content: {
    image: aidImage,
    linkType: 'internal',
    link: '/test1',
  },
};

// Sets view count of popup to limit spam.
// All view counts start at 1.
const setPopup = (setState, state, popup) => {
  // initial view
  if (!state[popup.name]) {
    setState({
      ...state,
      [popup.name]: popup,
    });
  } else if (state[popup.name].viewCount > 1) {
    setState({
      ...state,
      [popup.name]: {
        ...state[popup.name],
        ['showPopup']: false,
      },
    });
  } else {
    //update view count here, need to showpopup true to show popup again until it reaches viewcount threshold
    setState({
      ...state,
      [popup.name]: {
        ...state[popup.name],
        ['viewCount']: state[popup.name]['viewCount'] + 1,
        ['showPopup']: true,
      },
    });
  }
};

// Manager Function - chooses which page to display which popup on.
export const popupManager = (setState, state, location) => {
  if (miccosukeePopup.showPopup) {
    if (location.pathname === '/') {
      setTimeout(() => {
        setPopup(setState, state, miccosukeePopup);
      }, 700);
    }
  }
  if (mrgPopup.showPopup) {
    //mrg to mcr change
    if (location.pathname === '/mrg' || location.pathname === '/mcr') {
      setTimeout(() => {
        setPopup(setState, state, mrgPopup);
      }, 700);
    }
  }
  if (mrgGamingPromotionsPopup.showPopup) {
    //mrg to mcr change
    if (
      location.pathname === '/mrg/promotions' ||
      location.pathname === '/mcr/promotions'
    ) {
      setTimeout(() => {
        setPopup(setState, state, mrgGamingPromotionsPopup);
      }, 700);
    }
  }
  if (golfPopup.showPopup) {
    if (location.pathname === '/golf') {
      setTimeout(() => {
        setPopup(setState, state, golfPopup);
      }, 700);
    }
  }
  if (villagePopup.showPopup) {
    if (location.pathname === '/village') {
      setTimeout(() => {
        setPopup(setState, state, villagePopup);
      }, 700);
    }
  }
  if (airboatsPopup.showPopup) {
    if (location.pathname === '/airboats') {
      setTimeout(() => {
        setPopup(setState, state, airboatsPopup);
      }, 700);
    }
  }
  if (miccosukeeEventsPopup.showPopup) {
    if (location.pathname === '/events') {
      setTimeout(() => {
        setPopup(setState, state, miccosukeeEventsPopup);
      }, 700);
    }
  }
  if (historyPopup.showPopup) {
    if (location.pathname === '/history') {
      setTimeout(() => {
        setPopup(setState, state, historyPopup);
      }, 700);
    }
  }
  if (administrationPopup.showPopup) {
    if (location.pathname === '/administration') {
      setTimeout(() => {
        setPopup(setState, state, administrationPopup);
      }, 700);
    }
  }
};
