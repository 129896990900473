// Images
import cardImage1 from 'assets/media/img/mrg/monthly_promos/0_recurring/NewMembersBonus_Logo.png';
import cardImage2 from 'assets/media/img/mrg/monthly_promos/0_recurring/GiftGiveaway_Logo.png';
import cardImage3 from 'assets/media/img/mrg/monthly_promos/2_february/HomeRunHotSeat_Logo.png';
import cardImage4 from 'assets/media/img/mrg/monthly_promos/2_february/GridironShowdown_Logo.png';
import cardImage5 from 'assets/media/img/mrg/monthly_promos/0_recurring/VIPGiveawayWhiteCard_Logo.png';
import cardImage6 from 'assets/media/img/mrg/monthly_promos/0_recurring/PickYourPresentBDayHotSeat_Logo.png';
import cardImage7 from 'assets/media/img/mrg/monthly_promos/0_recurring/NewMembers_ReferralsWinBig_Logo.png';

export const february = {
  month: 'february',
  monthEs: 'febrero',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/february_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/february_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
