export const october = {
  month: 'october',
  monthEs: 'octubre',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mrg_monthly_promos/october_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mrg_monthly_promos/october_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
