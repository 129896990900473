// Images
import cardImage1 from 'assets/media/img/mrg/monthly_promos/0_recurring/NewMemberBonus_Logo.png';
import cardImage2 from 'assets/media/img/mrg/monthly_promos/0_recurring/GiftGiveaway_Logo.png';
import cardImage3 from 'assets/media/img/mrg/monthly_promos/5_may/Revised2xtierbonus_Logo.png';
import cardImage4 from 'assets/media/img/mrg/monthly_promos/5_may/RaceTo25KSlotTournament_Logo.png';
import cardImage5 from 'assets/media/img/mrg/monthly_promos/5_may/CashBagGrab_Logo.png';
import cardImage6 from 'assets/media/img/mrg/monthly_promos/5_may/MayMoneyMadness_Logo.png';
import cardImage7 from 'assets/media/img/mrg/monthly_promos/5_may/100KGoldenTicket_Logo.png';
import cardImage8 from 'assets/media/img/mrg/monthly_promos/0_recurring/PickYourPresentBDayHotSeat_Logo.png';
import cardImage9 from 'assets/media/img/mrg/monthly_promos/0_recurring/NewMembers_ReferralsWinBig_Logo.png';

export const may = {
  month: 'may',
  monthEs: 'mayo',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/may_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/may_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
