export const policePages = [
  {
    pageNumber: 1,
    location: "/administration/police",
  },
  {
    pageNumber: 2,
    location: "/administration/police/locations",
  },
  {
    pageNumber: 3,
    location: "/administration/police/specialized-units",
  },
];
