export const keys = {
  lambdaEmailAPI: 'MsiDNL8cuZ8mdqe6f8T756HtViqnqnUi7MdYeyBT',
  lambdaMailchimpFunctionAPI: 'Ss9UiFSmqC4PRtUHKatSDaLf77CwZBtN9SKPRq66',
  lambdaEventbriteAPI: 'fowUx8vTIa9hCIRA681jz44nfZugWcy161McoiTa',
  lambdaReCaptchaAPI: 'wmqRK4VoNi7p3ZI9tvuUK9920Vk9kOum3C5HV0mM',
  recaptchaSecret: '6LecMmgbAAAAAG8ecz2Bfx63tlxxYIpku_0XkGa8',
  lambdaPrerenderAPI: 'sLOhxxLV5Z3dcIvoCbPsn1OO6TrWUgdh4KqOCmXl',
  testAPI: 'yknFkyUtlt21revxVqveRUSRCyhgmdB57zIx1XN0',
  eventbrite: 'Y6ZUQIE6RUC4MTUIG2TN',
  gtmID: 'GTM-MW544LL',
};
