export const december = {
  month: 'december',
  monthEs: 'diciembre',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mrg_monthly_promos/december_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mrg_monthly_promos/december_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
