export const september = {
  month: 'september',
  monthEs: 'septiembre',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mrg_monthly_promos/september_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mrg_monthly_promos/september_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
