export const may = {
  //type: 'card',
  type: 'calendar',
  month: 'may',
  monthEs: 'mayo',
  calendarLink: 'https://mapa-media.s3.amazonaws.com/mcr/may_pokerCalendar.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mcr/may_pokerCalendar.pdf',
  data: undefined,
  dataEs: undefined,
};
