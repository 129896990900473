import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export const june = {
  type: 'calendar',
  month: 'june',
  monthEs: 'junio',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mcr/june_pokerCalendar.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mcr/june_pokerCalendar.pdf',
  data: undefined,
  dataEs: undefined,
};
