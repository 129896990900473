export const november = {
  month: 'november',
  monthEs: 'noviembre',
  calendarLink:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/november_en.pdf',
  calendarLinkEs:
    'https://mapa-media.s3.amazonaws.com/mcr_monthly_promos/november_sp.pdf',
  data: undefined,
  dataEs: undefined,
};
