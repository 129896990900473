import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import cyan from '@material-ui/core/colors/cyan';
import lightGreen from '@material-ui/core/colors/lightGreen';
import teal from '@material-ui/core/colors/teal';
import brown from '@material-ui/core/colors/brown';
import red from '@material-ui/core/colors/red';

//Error Color
export const errorColor = '#f44336';

//Link Color
export const standardLinkColor = {
  color: '#3f51b5',
  hover: '#2c387e',
};

export const aid21Colors = {
  red: '#ff0036',
  yellow: '#fff100',
  rgbRedNumbers: '255 0 55',
};

//MRG Color
export const mrgColor = purple;

//Golf Color
export const golfColor = green;

//Village Color
export const villageColor = cyan;

//Airboats Color
export const airboatsColor = teal;

//History Color
export const historyColor = brown;

//Administration Color
export const administrationColor = orange;
