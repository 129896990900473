//Our little shack (The Village)
//CLOSED

export const ourLittleShackMenu = [
  {
    name: 'Breakfast',
    subMenu: [
      {
        name: 'Main',
        items: [
          { name: 'Pancakes with bacon or sausage', price: '$6.00' },
          { name: 'Bacon & Eggs', price: '$6.00' },
          { name: 'Sausage & Eggs', price: '$6.00' },
          { name: 'Spam & Eggs', price: '$6.00' },
          { name: 'Smoked Sausage with fries', price: '$4.00' },
          { name: 'Custom Omelette', price: '$6.00' },
          { name: 'French Toast with bacon', price: '$6.00' },
          { name: 'Grits', price: '$3.00' },
          { name: 'Home Fries', price: '$3.00' },
          { name: 'Hash Browns', price: '$3.00' },
          { name: 'Bagel', price: '$2.00' },
          { name: 'Corn Beef Hash', price: '$3.00' },
        ],
      },
    ],
  },
  {
    name: 'Lunch',
    subMenu: [
      {
        name: 'Main',
        items: [
          { name: 'Cheeseburger', price: '$7.00' },
          { name: 'Bacon Cheeseburger', price: '$8.00' },
          { name: 'Buffalo Burger', price: '$10.00' },
          { name: 'Gator Bites', price: '$15.00' },
          { name: 'Frog Legs', price: '$20.00' },
          { name: 'Shrimp', price: '$10.00' },
          { name: 'Grilled Chicken Sandwich', price: '$7.00' },
          { name: 'Chicken or Steak Quesadilla', price: '$7.00' },
          { name: 'Chicken Tenders (5 pc)', price: '$11.50' },
          { name: 'Chicken Wings (7 pc)', price: '$8.00' },
          { name: 'Grilled Cheese Sandwich', price: '$5.00' },
          { name: 'Chili Dog', price: '$5.00' },
          { name: 'Chicken nuggets (5pc)', price: '$5.00' },
          { name: 'Club Sandwich (Sandwich only, $5)', price: '$9.00' },
          { name: 'BLT Sandwich (Sandwich only, $4)', price: '$8.00' },
          { name: 'Steak Sandwich', price: '$7.00' },
          { name: 'Chicken Wrap', price: '$7.00' },
          { name: 'Steak Wrap', price: '$8.00' },
        ],
      },
      {
        name: 'Sides',
        items: [
          { name: 'French Fries', price: '$3.00' },
          { name: 'Mozarella Sticks', price: '$3.00' },
          { name: 'Sweet Potato Fries', price: '$4.00' },
          { name: 'Onion Rings', price: '$3.00' },
        ],
      },
      {
        name: 'Salads',
        items: [
          { name: 'Salad with chicken', price: '$7.00' },
          { name: 'Salad with shrimp', price: '$8.00' },
          { name: 'Salad with steak', price: '$9.00' },
        ],
      },
    ],
  },
  {
    name: "Kid's Menu",
    items: [
      { name: 'Mac & Cheese', price: '$3.00' },
      { name: 'Hotdog', price: '$4.00' },
      { name: 'Pizza', price: '$5.00' },
    ],
  },
  {
    name: 'Drinks',
    items: [
      { name: 'Red Bull', price: '$3.00' },
      { name: 'Milkshakes (Vanilla/Chocolate)', price: '$4.00' },
      { name: 'Coffee', price: '$2.00' },
      { name: 'Cuban Coffee', price: '$1.50' },
      { name: 'Water', price: '$1.00' },
      { name: 'Soda', price: '$1.00' },
    ],
  },
];

// B1 Grill
export const b1GrillMenu = [
  {
    name: 'Appetizers',
    items: [
      {
        name: 'Combo Special Croquetas (3 Croquetas with 2 Saltines)',
        price: '$3.00',
      },
      { name: 'Croquetas', price: '$1.50' },
      { name: 'Yuca Rellenas', price: '$3.00' },
      { name: 'Papa Rellenas', price: '$3.00' },
      { name: 'Toast', price: '$2.50' },
      { name: 'Pan de Bono', price: '$2.50' },
      { name: 'Cuban Toast', price: '$3.00' },
      { name: 'Pastelitos', price: '$2.50' },
      { name: 'Papa Rellena', price: '$3.00' },
      { name: 'Cheese Tequeño', price: '$3.00' },
      { name: 'Guava Cheese Tequeño', price: '$3.00' },
      { name: 'Chicken Empanada', price: '$3.75' },
      { name: 'Spinach Empanada', price: '$3.75' },
      { name: 'Chorizo Empanada', price: '$3.75' },
      { name: 'Meat Empanada', price: '$3.75' },
      { name: 'Jamaican Curry Chicken Pattie', price: '$4.00' },
      { name: 'Jamaican Beef Spicy Pattie', price: '$4.00' },
      { name: 'Jamaican Beef Mild Pattie', price: '$4.00' },
      { name: 'Guava Cheese Pastelito', price: '$3.00' },
      { name: 'Cheese Pastelito', price: '$2.50' },
      { name: 'Guava Pastelito', price: '$2.50' },
      { name: 'Pastelitos Coco', price: '$2.50' },
    ],
  },
  {
    name: 'Combos',
    subMenu: [
      {
        name:
          '$8 Combo includes: 6 oz. Soup & Half Sandwich with a side of 6 oz. Fries and a 16 oz. Drink.',
        items: [
          { name: 'Soup, Ham & Cheese Sandwich', price: '$8.00' },
          { name: 'Soup, BLT Sandwich', price: '$8.00' },
          { name: 'Soup, Turkey & Provolone Sandwich', price: '$8.00' },
          { name: 'Soup, Grilled Cheese Sandwich', price: '$8.00' },
          { name: 'Soup, Tuna Salad Sandwich', price: '$8.00' },
          { name: 'Soup, Chicken Salad Sandwich', price: '$8.00' },
          { name: 'Soup & Salad', price: '$8.00' },
        ],
      },
    ],
  },
  {
    name: 'Hot Items and Pizza',
    items: [
      { name: 'Hot Dog & Fries', price: '$7.00' },
      { name: 'Chicken Tenders', price: '$11.50' },
      { name: 'Chicken Wings', price: '$14.00' },
      { name: 'Cheese Pizza (Slice)', price: '$4.00' },
      { name: 'Pepperoni Pizza (Slice)', price: '$4.00' },
      { name: 'Whole Pizza', price: '$20.00' },
    ],
  },
  {
    name: 'Soups and Sandwiches',
    subMenu: [
      {
        name: 'Soups',
        items: [
          { name: 'Miccosukee Chicken Soup', price: '$6.00' },
          { name: 'Soup of the Day', price: '$5.52' },
        ],
      },
      {
        name: 'Sandwiches',
        items: [
          { name: 'Grilled Cheese', price: '$7.00' },
          { name: 'Chicken Salad Sandwich', price: '$10.00' },
          { name: 'BLT Sandwich', price: '$9.50' },
          { name: 'Ham & Cheese Sandwich', price: '$9.00' },
          { name: 'Turkey & Provolone Sandwich', price: '$9.50' },
          { name: 'Croqueta Preparada', price: '$9.50' },
          { name: 'Tuna Salad Sandwich', price: '$10.00' },
          { name: 'Chicken Sandwich', price: '$10.50' },
          { name: 'Cuban Sandwich', price: '$10.50' },
          { name: 'Club Sandwich', price: '$12.00' },
          { name: 'Steak Palomilla Sandwich', price: '$12.00' },
          { name: 'Pork Special Sandwich', price: '$10.00' },
        ],
      },
      {
        name: 'Burgers',
        items: [
          { name: 'Hamburger', price: '$10.00' },
          { name: 'Cheeseburger', price: '$10.50' },
        ],
      },
    ],
  },

  {
    name: 'Sides',
    items: [{ name: 'French Fries (8 oz.)', price: '$4.50' }],
  },
  {
    name: 'Drinks',
    subMenu: [
      {
        name: 'Cold Beverages',
        items: [
          { name: 'Import Beers', price: '$8.00' },
          { name: 'Domestic Beers', price: '$7.00' },
          { name: 'Wine', price: '$9.00' },
          { name: 'Soda Can (12 oz.)', price: '$2.50' },
          { name: 'Soda Bottle (20 oz.)', price: '$3.00' },
          { name: 'Fountain Soda', price: '$2.00' },
          { name: 'Monster® Energy Drink', price: '$4.00' },
          { name: 'Powerade®', price: '$3.50' },
          { name: 'Dunkin Donuts® Beverages', price: '$3.50' },
          { name: 'Gold Peak® Tea', price: '$3.00' },
          { name: 'Water Bottle', price: '$1.50' },
          { name: 'Juice', price: '$3.00' },
        ],
      },
      {
        name: 'Hot Beverages',
        items: [
          { name: 'Hot Tea', price: '$1.50' },
          { name: 'American Coffee', price: '$2.00' },
          { name: 'Colada', price: '$2.50' },
          { name: 'Cortadito', price: '$3.00' },
          { name: 'Café con Leche', price: '$4.00' },
          { name: 'Hot Chocolate', price: '$4.00' },
          { name: 'Cappuccino', price: '$4.50' },
        ],
      },
    ],
  },
];

// Cafe Hammock
export const cafeHammock = [
  {
    name: 'Appetizers & Soups',
    subMenu: [
      {
        name: 'Appetizers',
        items: [
          { name: 'Chicharrones', price: '$15' },
          { name: 'Fried Calamari', price: '$15' },
          { name: 'Conch Fritters', price: '$15' },
        ],
      },
      {
        name: 'Soups (served in a sourdough bread bowl)',
        items: [
          { name: 'Broccoli & Cheese', price: '$10' },
          { name: 'Clam Chowder', price: '$10' },
        ],
      },
    ],
  },
  {
    name: 'Pasta & Pizza',
    subMenu: [
      {
        name: 'Pasta',
        items: [
          {
            name:
              'Baked Ziti - Ziti, Ricotta, Pomodorina, Parmesan, Mozzarella',
            price: '$24',
          },
          {
            name: 'Lasagna - Layers of beef, Sausages, Cheese, Onions, Peppers',
            price: '$24',
          },
          { name: 'Linguini & Clam Sauce - Red or White', price: '$24' },
        ],
      },
      {
        name:
          'Build Your Own Pasta (Served with a Caesar Salad or Soup with Ciabatta Bread) - $25',
        items: [
          { name: 'Choose a Pasta - Ziti, Spaghetti, Linquini', price: '' },
          { name: 'Choose a Sauce - Marinara, Alfredo, Pesto', price: '' },
          {
            name: 'Choose a Protein - Shrimp, Meatballs, Chicken Breast',
            price: '',
          },
        ],
      },
      {
        name: 'Pizza',
        items: [
          { name: 'Cheese - Tomato Sauce, House Blend Cheeses', price: '$15' },
          {
            name: 'Margherita - Fresh Tomatoes, Mozzarella, Basil',
            price: '$15',
          },
          {
            name: 'Chef’s Pie - Sausage, Meatball, Roasted Peppers',
            price: '$15',
          },
        ],
      },
    ],
  },
  {
    name: 'Entrées',
    subMenu: [
      {
        name: 'Main Courses',
        items: [
          {
            name:
              'Chicken or Veal Parmigiana - Topped with house blend Tomato Sauce, Mozzarella',
            price: '$25',
          },
          {
            name:
              'Broiled Atlantic Salmon - Center-cut Fillet with Fresh Herbs',
            price: '$25',
          },
          {
            name:
              'Pork Tenderloin Bites - Garlic Butter, Pork Bites with Lemon Green Beans',
            price: '$25',
          },
        ],
      },
      {
        name:
          'Meats (Served with choice of Baked Potato, Mashed Potato, White Rice or any Pasta)',
        items: [
          { name: 'Half Rack BB Ribs', price: '$25' },
          { name: 'New York Strip', price: '$28' },
          { name: 'Churrasco', price: '$30' },
          { name: 'Pork Tomahawk', price: '$60' },
          { name: 'Beef Tomahawk', price: '$95' },
        ],
      },
      {
        name: 'Surf & Turf (Served with choice of Meat and Two Sides) +$15',
        items: [{ name: 'Maine Lobster Tail', price: '' }],
      },
    ],
  },
  {
    name: 'Kids',
    subMenu: [
      {
        name: 'Kids Menu (served with French Fries)',
        items: [
          { name: 'Chicken Wings', price: '$12' },
          { name: 'Chicken Tenders', price: '$12' },
          { name: 'Cheeseburger', price: '$12' },
          { name: 'Mac & Cheese', price: '$12' },
          { name: 'Hamburger', price: '$12' },
        ],
      },
    ],
  },
];

// Max's Grab & Go
export const maxsgrabandgoMenu = [
  {
    name: 'Breakfast',
    subMenu: [
      {
        name: 'Breakfast available from 7:00 A.M. — 10:00 A.M.',
        items: [{ name: '', price: '' }],
      },
      {
        name: 'Omelets (Served with home potatoes, toast, and American coffee)',
        items: [
          { name: 'Veggie', price: '$9.00' },
          { name: 'Ham & Cheese', price: '$9.50' },
          { name: 'All Meat', price: '$10.50' },
        ],
      },
      {
        name: 'Combo (Served with home potatoes, toast, and American coffee)',
        items: [
          {
            name:
              'Two Eggs (fried or scrambled) with two proteins (sausage, bacon, or ham)',
            price: '$8.00',
          },
        ],
      },
      {
        name: 'Extras',
        items: [
          { name: 'Oatmeal (6 oz.)', price: '$2.50' },
          { name: 'French Fries (4 oz.)', price: '$2.50' },
          { name: 'Steak Ham (4 oz.)', price: '$3.50' },
          { name: 'Hash Brown (2 pc)', price: '$2.00' },
          { name: 'Home Potatoes (4 oz.)', price: '$2.50' },
          { name: 'Bacon (4 pc)', price: '$3.50' },
          { name: 'Sausage (2 pc)', price: '$2.50' },
          { name: 'Two Eggs (fried or scrambled)', price: '$2.50' },
          { name: 'Egg White', price: '$2.50' },
          { name: 'Cuban Toast', price: '$3.00' },
          { name: 'Toast', price: '$2.50' },
          { name: 'Extra Sausage', price: '$2.50' },
          { name: 'Extra Bacon', price: '$2.50' },
          {
            name: 'American, Mozzarella, Cheddar, or Swiss Cheese',
            price: '$2.00',
          },
          { name: 'Onion, Pepper, Tomato, or Mushroom', price: '$1.50' },
        ],
      },
    ],
  },
  {
    name: 'A la Carté',
    items: [
      { name: 'Chip ', price: '$1.00' },
      { name: 'Croqueta ', price: '$1.50' },
      { name: 'Pandabono ', price: '$2.50' },
      { name: 'Pastelito ', price: '$2.50' },
      { name: 'Papa / Yuca Rellena ', price: '$3.00' },
      { name: 'Tequeño ', price: '$3.00' },
      { name: 'Croquetas (3) ', price: '$3.00' },
      { name: 'Empanadas ', price: '$3.75' },
      { name: 'Hot Dog ', price: '$3.00' },
      { name: 'Rice Pudding ', price: '$3.00' },
      { name: 'Flan ', price: '$3.00' },
      { name: 'Tres Leches ', price: '$3.00' },
      { name: 'Slice of Cheese Pizza ', price: '$3.00' },
      { name: 'Slice of Pepperoni Pizza ', price: '$3.00' },
      { name: 'Jamaican Patty ', price: '$3.00' },
    ],
  },
  {
    name: 'Combo',
    items: [{ name: 'Hot Dog & Beer', price: '$5.50' }],
  },
  {
    name: 'Country Bowl Combos',
    items: [
      {
        name:
          'Cuban - Roasted pork, white rice, black beans, fried plantain, and mojo sauce',
        price: '$14.95',
      },

      {
        name:
          'Peruvian - Lomo or chicken saltado, white rice or lo-mein, french fries, and Huancaina sauce',
        price: '$14.95',
      },

      {
        name:
          'Mexican - Beef or chicken fajita, cilantro rice, red beans, guacamole, and pico de gallo',
        price: '$14.95',
      },

      {
        name:
          'Colombian - Chorizo, churrasco, mini chicharron, white rice, red beans, fried plantain, mini arepa, and fried egg',
        price: '$14.95',
      },

      {
        name:
          'Chinese - Chicken or beef fried rice, mini egg roll, and sweet & sour sauce',
        price: '$14.95',
      },

      {
        name:
          'Italian - Grilled chicken alfredo or pesto linguini, parmesan cheese, and garlic bread',
        price: '$14.95',
      },

      {
        name:
          'American Super Max - Salmon (4 oz.), shrimp skewer, lobster tail (4 oz.), white rice, and baked potato',
        price: '$14.95',
      },
    ],
  },
  {
    name: 'Drinks',
    items: [
      { name: 'Import Beers', price: '$8.00' },
      { name: 'Domestic Beers', price: '$7.00' },
      { name: 'Wine', price: '$9.00' },
      { name: 'Hot Tea', price: '$1.50' },
      { name: 'Soda Can (12 oz.)', price: '$2.50' },
      { name: 'Water Bottle', price: '$1.50' },
      { name: 'Colada', price: '$2.50' },
      { name: 'Cortadito', price: '$3.00' },
      { name: 'Soda Bottle (20 oz.)', price: '$3.00' },
      { name: 'Sweet / Unsweet Tea', price: '$3.00' },
      { name: 'Dunkin Donuts® Coffee', price: '$3.50' },
      { name: 'Powerade®', price: '$3.00' },
      { name: 'Minute Maid® Juice', price: '$3.00' },
      { name: 'Monster® Energy Drink', price: '$4.00' },
      { name: 'Café con Leche', price: '$4.00' },
      { name: 'Hot Chocolate', price: '$4.00' },
      { name: 'Cappuccino', price: '$4.50' },
      { name: 'Fountain Soda', price: '$2.00' },
    ],
  },
];
