const virtualLoginModalStyle = {
  modal: {
    borderRadius: '6px',
    width: '100%',
  },
  modalHeader: {
    borderBottom: 'none',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '0',
    paddingLeft: '24px',
    minHeight: '16.43px',
  },
  modalTitle: {
    margin: '0',
    lineHeight: '1.42857143',
  },
  modalCloseButton: {
    color: '#999999',
    marginTop: '-12px',
    WebkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    fontSize: 'inherit',
    opacity: '.9',
    textShadow: 'none',
    fontWeight: '700',
    lineHeight: '1',
    float: 'right',
  },
  modalClose: {
    width: '16px',
    height: '16px',
  },
  modalBody: {
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    position: 'relative',
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    paddingTop: '0',
    margin: '0',
  },
  modalFooterCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  contentSection: {
    //DESKTOP VIEW
    '@media screen and (min-width: 960px)': {
      padding: '10px',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: '400',
    //DESKTOP VIEW
    '@media screen and (min-width: 960px)': {
      fontSize: '24px',
    },
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: '400',
    //DESKTOP VIEW
    '@media screen and (min-width: 960px)': {
      fontSize: '20px',
    },
  },
  imageStyle: {
    maxWidth: '100%',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    //DESKTOP VIEW
    '@media screen and (min-width: 960px)': {
      maxWidth: '700px',
    },
  },
  //DESKTOP VIEW
  '@media screen and (min-width: 960px)': {
    modal: {
      borderRadius: '6px',
      minWidth: '500px',
      maxWidth: '800px',
    },
  },
};

export default virtualLoginModalStyle;
