/*
import React from "react";
import classNames from "classnames";
import { getColorClass } from "components/CustomIcons/getColorClass.js";

const CrocodileIcon = (props) => {
  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        viewBox="0 0 64 64"
        aria-hidden="true"
        className={classNames(
          "MuiSvgIcon-root",
          props.className ? props.className : "",
          getColorClass(props.color)
        )}
      >
        <g id="CrocGlyph">
          <path d="m53.707 38.707a1 1 0 0 1 -1.414 0l-3.21-3.21-3.3 4.128a1 1 0 0 1 -1.562 0l-3.221-4.025-3.219 4.024a1 1 0 0 1 -1.562 0l-3.7-4.624h-1.519a8.205 8.205 0 0 1 -5.707-2.293 6.253 6.253 0 0 0 -4.293-1.707h-9v-2h9a8.205 8.205 0 0 1 5.707 2.293 6.253 6.253 0 0 0 4.293 1.707h28.482a3.894 3.894 0 0 0 -3.388-2 1.084 1.084 0 0 1 -1.094-1 3 3 0 0 0 -6 0 1 1 0 0 1 -1 1c-5.931 0-7.847-1.5-12.047-4.769 0 0-.631-.5-.66-.524l-2-2a1 1 0 0 1 -.293-.707c0-2.154-2.749-4-5-4a5.954 5.954 0 0 0 -4.155 2.535 5.7 5.7 0 0 1 -4.845 2.465h-15v21h10.344a20.546 20.546 0 0 1 -9.213-8.505l1.738-.99a18.663 18.663 0 0 0 2.848 3.8 20.259 20.259 0 0 0 11.374 5.695h.909a24.114 24.114 0 0 0 6.573-.923 41.08 41.08 0 0 1 -12.064-4.206 41.009 41.009 0 0 1 -10.25-8.2l1.482-1.342a39.034 39.034 0 0 0 9.75 7.8c5.648 3.187 12.595 4.406 17.509 4.871h9c1.586 0 1.9-.312 2.293-.707 1.427-1.426 3.116-1.372 4.743-1.314.316.01 3.964.021 3.964.021a4.946 4.946 0 0 0 5-5v-1h-2.586zm-2.707-9.707h2v2h-2zm-23-8a3 3 0 1 1 -3 3 3 3 0 0 1 3-3zm-20 7h-2v-2h2zm14 8h-2v-2h2zm4 3h-2v-2h2z" />
          <circle cx="28" cy="24" r="1" />
          <path d="m54.586 35h-3.172l1.586 1.586z" />
          <path d="m38.919 35h-3.838l1.919 2.399z" />
          <path d="m46.919 35h-3.838l1.919 2.399z" />
        </g>
      </svg>
    </React.Fragment>
  );
};

export default CrocodileIcon;
*/

import React from "react";
import classNames from "classnames";
import { getColorClass } from "components/CustomIcons/getColorClass.js";

const GatorIcon = (props) => {
  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        viewBox="0 -132 512.001 512"
        aria-hidden="true"
        className={classNames(
          "MuiSvgIcon-root",
          props.className ? props.className : "",
          getColorClass(props.color)
        )}
      >
        <path d="m281 210.015625h-29.699219l9.898438 19.800781zm0 0" />
        <path d="m332 240.015625-16.800781-22.199219-22.199219 22.199219zm0 0" />
        <path d="m377 210.015625h-30l15 20.101563zm0 0" />
        <path d="m422 240.015625-15-20.097656-15 20.097656zm0 0" />
        <path d="m467 210.015625h-30l15 20.101563zm0 0" />
        <path d="m482 240.015625h30v-30h-7.5zm0 0" />
        <path d="m467 60.015625c-11.398438 0-21.898438 4.5-30 11.402344-8.101562-6.902344-18.601562-11.402344-30-11.402344-19.5 0-36.300781 12.601563-42.300781 30h-64.199219c2.101562-10.800781 2.101562-21.898437-.601562-33-6.300782-26.699219-27.597657-48-54-54.597656-30.296876-7.5-60.898438 3.597656-78.898438 27.597656h-32c-15.300781-20.699219-40.199219-32.097656-66.597656-29.699219-38.402344 3.300782-68.402344 37.5-68.402344 78.300782v176.398437c0 41.402344 33.601562 75 75 75h362c36.300781 0 66.597656-25.800781 73.5-60h-253.5c-4.730469 0-10.078125-2.695313-13.5-8.398437-5.84375-11.691407-28.515625-57.03125-30-60-.101562-.203126-.105469-.210938 0 0-2.101562-4.5-2.101562-10.199219.601562-14.402344 3-4.496094 7.800782-7.199219 12.898438-7.199219h285c0-3.921875 0-72.167969 0-75.300781-.300781-24.898438-20.101562-44.699219-45-44.699219zm-392 30c-8.402344 0-15-6.597656-15-15 0-8.398437 6.597656-15 15-15s15 6.601563 15 15c0 8.402344-6.597656 15-15 15zm152 0c-8.402344 0-15-6.597656-15-15 0-8.398437 6.597656-15 15-15s15 6.601563 15 15c0 8.402344-6.597656 15-15 15zm0 0" />
      </svg>
    </React.Fragment>
  );
};

export default GatorIcon;
